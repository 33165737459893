.chip {

    background-color: hsl(0,0,15%);
    border-radius: 10px;
    color: hsl(17, 8%, 84%);
    padding: 10px 12px;
    font-size: 12px;
    font-weight: 600;
    &.intermediate {
        background: none;
        border-radius: 10px;
        border: 1px solid hsl(0,0,15%);;
        color: hsl(0,0,15%);
    }

}