@import url(./styles/helpers.scss);

.container-body {
  background-color: 	hsl(34deg, 78%, 91%);
  height: 100%;
  padding: 100px 0;
}

.container {
  max-width: 700px;
  padding: 0 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 100px;
  // height: fit-content;
}

.title-font {
  color: #262626;
  // font-size: 100px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;

  font-size:clamp(3.5rem, 10vw + 1rem, 10rem);
}

.description {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  font-size: 1.5rem;
}

.square-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  gap: 20px;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 800px;
  grid-auto-rows: min-content;
}

.links-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  gap: 20px;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 800px;
  grid-auto-rows: min-content;

  display: flex;
  flex-wrap: wrap;
}

.sports-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  // Experimenting with grid
  display: grid;
  grid-template-columns: 1fr;
  max-width: 800px;
  grid-auto-rows: min-content; /* OR min-content*/

  // grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  // grid-template-columns: 300px 300px;

}

.resume-square {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: hsl(34deg, 78%, 80%);
  width: 200px;
  padding: 20px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
      background: hsl(34, 78%, 70%);
      .skill-bar:not(.active) {
          background-color: antiquewhite;
          ;
      }
  }
  p {line-height: 24px; font-weight: 600;}


}
