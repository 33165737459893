// ------------------ STYLE GUIDE ---------------------------------------

/* Display/Display-2 */
.display-2 {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 50px;
}

.body-subtitle {
    /* Paragraph/body-subtitle */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
}

.center {
    text-align: center;
}
.left {
    text-align: left;
}
/*--------------------COLORS--------------------------------------------*/
.orange-txt {
    color: darkorange !important;
}

.bold {
    font-weight: bold !important;
}

/* -------------------LEFT AND RIGHT------------------------------------- */

.plxs {
    padding-left: 2px !important
}

.pls {
    padding-left: 4px !important
}

.plm {
    padding-left: 8px !important
}

.pll {
    padding-left: 16px !important
}

.plxl {
    padding-left: 40px !important
}

.prxs {
    padding-right: 2px !important
}

.prs {
    padding-right: 4px !important
}

.prm {
    padding-right: 8px !important
}

.prl {
    padding-right: 16px !important
}

.prxl {
    padding-right: 40px !important
}

.mlxs {
    margin-left: 2px !important
}

.mls {
    margin-left: 4px !important
}

.mlm {
    margin-left: 8px !important
}

.mll {
    margin-left: 16px !important
}

.mlxl {
    margin-left: 40px !important
}

.mrxs {
    margin-right: 2px !important
}

.mrs {
    margin-right: 4px !important
}

.mrm {
    margin-right: 8px !important
}

.mrl {
    margin-right: 16px !important
}

.mrxl {
    margin-right: 40px !important
}

/* -------------------TOP AND BOTTOM------------------------------------- */
.ptxs {
    padding-top: 2px !important
}

.pts {
    padding-top: 4px !important
}

.ptm {
    padding-top: 8px !important
}

.ptl {
    padding-top: 16px !important
}

.ptxl {
    padding-top: 40px !important
}

.pbxs {
    padding-bottom: 2px !important
}

.pbs {
    padding-bottom: 4px !important
}

.pbm {
    padding-bottom: 8px !important
}

.pbl {
    padding-bottom: 16px !important
}

.pbxl {
    padding-bottom: 40px !important
}

.mtxs {
    margin-top: 2px !important
}

.mts {
    margin-top: 4px !important
}

.mtm {
    margin-top: 8px !important
}

.mtl {
    margin-top: 16px !important
}

.mtxl {
    margin-top: 40px !important
}

.mt48 {
    margin-top: 48px !important
}

.mbxs {
    margin-bottom: 2px !important
}

.mbs {
    margin-bottom: 4px !important
}

.mbm {
    margin-bottom: 8px !important
}

.mbl {
    margin-bottom: 16px !important
}

.mbxl {
    margin-bottom: 40px !important
}
.mt80 {
    margin-top: 80px;
}

/* -------------------flex------------------------------------- */

// Totally unnecessary use of a mixin, I know
@mixin gap($gap_px) {
    gap: $gap_px;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.gap-s {
        @include gap(8px)
    }

    &.gap-m {
        @include gap(16px)
    }

    &.gap-l {
        @include gap(20px)
    }

    &.gap-xl {
        @include gap(40px)
    }

    &.center {
        justify-content: center;
    }
    &.v-start {
        align-items: flex-start;
    }
    &.space-between {
        justify-content: space-between;
    }
    &.v-center {
        align-items: center;
    }
}

.flex-col {
    display: flex;
    flex-direction: column;

    &.gap-s {
        @include gap(8px)
    }

    &.gap-m {
        @include gap(16px)
    }

    &.gap-l {
        @include gap(20px)
    }

    &.gap-xl {
        @include gap(40px)
    }

    &.center {
        justify-content: center;
    }

    &.h-center {
        align-items: center;
    }
    &.h-start {
        align-items: flex-start;
    }
    &.space-between {
        justify-content: space-between;
    }
}

.flex-end {
    justify-content: flex-end;
}

.flex-space-between {
    justify-content: space-between;
}

.flex-center {
    justify-content: center;
}

.flex-space-around {
    justify-content: space-around;
}

.f-align-start {
    align-items: flex-start;
}

.f-align-end {
    align-items: flex-end;
}

.f-align-center {
    align-items: center;
}

.f-1 {
    flex: 1;
}

.f-2 {
    flex: 2;
}

.f-3 {
    flex: 3;
}

.f-4 {
    flex: 4;
}

.f-5 {
    flex: 5;
}

.f-6 {
    flex: 6;
}

/*-------------------Grid layout------------------------------------- */
.grid {
    display: grid;
    gap: 48px;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}


.grid-col-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
    column-gap: 48px;
}

.w100 {
    width: 100%;
}

[hidden] { display: none !important;}