.name {
    display: flex;
    gap: 10px;
    align-items: center;
}


.sport {
    p {line-height: 24px;}
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: hsl(34deg, 78%, 80%);
    width: clamp(250px, 100%, 300px);
    padding: 20px;
    cursor: pointer;
    border-radius: 6px;
    &:hover {
        background: hsl(34, 78%, 70%);
        .skill-bar:not(.active) {
            background-color: antiquewhite;
            ;
        }
    }

    &.expanded {
        height: fit-content;
        flex-direction: column;
        align-items: flex-start;
        max-width: 500px;
        background: hsl(34, 78%, 70%);
        .skill-bar:not(.active) {
            background-color: antiquewhite;
            ;
        }
        .name {
            font-weight: 700;
            font-size: 20px;
            display: flex;
            gap: 10px;
            align-items: center;
        }
    }
}


.skill-bar {
    background-color: antiquewhite;
    width: 30px;
    height: 5px;
}

.skill-level {
    display: flex;
    gap: 3px;
}

.skill-bar.active {
    background-color: #262626;
}