.company-square {
  background-color: hsl(34deg, 78%, 80%);
  // border: 4px solid hsl(10, 7%, 82%);
  height: auto;
  width: clamp(250px, 100%, 400px);
  padding: 20px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background: hsl(34, 78%, 70%);
    // border: 4px solid #262626;
  }
  .title {
    font-style: normal;
  
    line-height: 30px;
  
    font-weight: 700;
    font-size: 20px;
    /* text-align: center; */
  }
  
  .all-metadata {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .metadata {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-weight: bold;
    }
    .description {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .all-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
  &.expanded {
    background: hsl(34, 78%, 70%);
    // .all-metadata {display: flex; gap: 4px;}
  }
}

